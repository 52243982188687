// Set file variable
$filename: 'scss/custom/_featured-testimonials.scss';

/*==============================================================================
  @Featured Testimonials - #{$filename}
==============================================================================*/

.featured-testimonials {
  background: $shade;

  .media {
    padding-top: 2em;
    padding-bottom: 2em;
    border-top: 1px solid rgba($black, 0.1);
  }

  blockquote {
    padding: 0;
    background: none;
    font-size: 1em;
    line-height: 1.4em;
  }
}

@include media-min('medium') {
  .featured-testimonials {
    blockquote {
      padding: 0 0 0 1.5em;
      font-size: 1.2em;
      line-height: 1.6em;
    }
  }
}

.media-image-placeholder {
  flex: 0 0 200px;
  display: none;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  background: $white;
  border-radius: 100px;
  font-size: 3em;
  color: $accent;

  .ldquo {
    margin-bottom: -0.5em;
    font-size: 4em;
    line-height: 1em;
    vertical-align: middle;
    font-family: $font-family-serif;
  }
}

@include media-min('medium') {
  .media-image-placeholder {
    display: flex;
  }
}
